jQuery(document).ready(function($) {
	$('.four-col-slider-wrapper .all-slides').slick({
		arrows: false,
		dots: true,
		slidesToShow: 4,
		responsive: [{
			breakpoint: 1024,
			settings: {
				dots: true,
				slidesToShow: 2,
				slidesToScroll: 2,
			}
		}, 
		{
			breakpoint: 600,
			settings: {
				dots: true,
				slidesToShow: 1,
				slidesToScroll: 1,
			}
		}]
	});


}); /* end of as page load scripts */